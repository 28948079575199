

export const tokensDataMain = [
  { address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t', icon: 'usdt', name: 'USDT', decimals: 6 },
  { address: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8', icon: 'usdc', name: 'USDC', decimals: 6 },
  { address: 'TRpfGv87u8tmdNW9rst5bpw6N7aEAE94Uh', icon: 'exon', name: 'EXON', decimals: 6 },
  { address: 'TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9', icon: 'btc', name: 'BTC', decimals: 8 },
  { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', icon: 'eth', name: 'ETH', decimals: 18 },
  { address: 'TRLnYDwwb2s9z724h1vmjQeGaNCxnXTRi6', icon: 'trx', name: 'TRX', decimals: 6 },
  { address: 'TAMYFwFXSmZpcoBHt3gVJ47nPW4Vih3DWk', icon: 'anac', name: 'ANAC', decimals: 6 },
  {
    address: 'TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S',
    icon: 'sun',
    name: 'SUN',
    price: 'SUN-TRX',
    decimals: 18,
    oracle: '',
  },
  {
    address: 'TAFjULxiVgT4qWk6UZwjqwZXTSaGaqnVp4',
    icon: 'btt',
    name: 'BTT',
    price: 'BTT-TRX',
    decimals: 18,
    oracle: '',
  },
  {
    address: 'TKkeiboTkxXKJpbmVFbv4a8ov5rAfRDMf9',
    icon: 'sunold',
    name: 'SUNOLD',
    price: 'SUNOLD-TRX',
    decimals: 18,
    oracle: '',
  },
  {
    address: 'TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq',
    icon: 'nft',
    name: 'NFT',
    price: 'NFT-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9',
    icon: 'jst',
    name: 'JST',
    price: 'JST-TRX',
    decimals: 18,
    oracle: '',
  },
  {
    address: 'TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7',
    icon: 'win',
    name: 'WIN',
    price: 'WIN-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TKttnV3FSY1iEoAwB4N52WK2DxdV94KpSd',
    icon: 'dice',
    name: 'DICE',
    price: 'DICE-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TVgAYofpQku5G4zenXnvxhbZxpzzrk8WVK',
    icon: 'live',
    name: 'LIVE',
    price: 'LIVE-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT',
    icon: 'usdj',
    name: 'USDJ',
    price: 'USDJ-TRX',
    decimals: 18,
    oracle: '',
  },
  {
    address: 'TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4',
    icon: 'tusd',
    name: 'TUSD',
    price: 'TUSD-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TR3DLthpnDdCGabhVDbD3VMsiJoCXY3bZd',
    icon: 'ltc',
    name: 'LTC',
    price: 'LTC-TRX',
    decimals: 8,
    oracle: '',
  },
  {
    address: 'TDyvndWuvX5xTBwHPYJi7J3Yq8pq8yh62h',
    icon: 'ht',
    name: 'HT',
    price: 'HT-TRX',
    decimals: 18,
    oracle: '',
  },
];

export const tokensBaseMain = [
  {
    address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
    icon: 'usdt',
    name: 'USDT',
    price: 'USDT-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8',
    icon: 'usdc',
    name: 'USDC',
    price: 'USDC-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TRpfGv87u8tmdNW9rst5bpw6N7aEAE94Uh',
    icon: 'exon',
    name: 'EXON',
    price: 'EXON-TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9',
    icon: 'btc',
    name: 'BTC',
    price: 'EXON-TRX',
    decimals: 8,
    oracle: '',
  },
  {
    address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF',
    icon: 'eth',
    name: 'ETH',
    price: 'EXON-TRX',
    decimals: 18,
    oracle: '',
  },
  {
    address: 'TRLnYDwwb2s9z724h1vmjQeGaNCxnXTRi6',
    icon: 'trx',
    name: 'TRX',
    price: 'TRX-USD',
    decimals: 6,
    oracle: 'TAL6RWymPLepKsLGCzWPUTKQQcWFsuHfNE',
  },
  
];
