import { AppDispatch, getRootState } from 'store';
import initExonNode from 'utils/tronWeb/initExonNode';
import {
  LaunchpadAction,
  LaunchpadEnum,
  LaunchpadInfo,
  LaunchpadModalState,
  LaunchpadUserInfo,
} from './types';
import IDOFactory from 'contracts/IDOFactory.json';
import IDO from 'contracts/IDO.json';
import ITRX20 from '../../../contracts/ITRC20.json';
import Config from 'config/index';
import { ApplicationAcionCreator } from '../application/action-creator';
import { getTransactionResult } from 'utils/transaction/getTransactionResult';
import { getTransactionBuilder } from 'utils/getTransactionBuilder';
import { getLaunchPadPoolInfo, getTokensByAddress } from 'api/api';

export const launchpadActionCreator = {
  setLaunchpadPools: (pools: LaunchpadInfo[]): LaunchpadAction => ({
    type: LaunchpadEnum.SET_LAUNCHPAD_POOLS,
    payload: pools,
  }),
  setUserDataIsFetched: (
    userDataIsFetched: boolean,
    poolId: number,
  ): LaunchpadAction => ({
    type: LaunchpadEnum.SET_USER_DATA_IS_FETCHED,
    payload: { userDataIsFetched, poolId },
  }),
  setLaunchpadState: (state: LaunchpadModalState): LaunchpadAction => ({
    type: LaunchpadEnum.SET_LAUNCHPAD_STATE,
    payload: state,
  }),
  setCurrentPool: (
    launchpad: LaunchpadInfo,
    poolId: number,
  ): LaunchpadAction => ({
    type: LaunchpadEnum.SET_ACTIVE_IDO_POOL,
    payload: { activeIdo: launchpad, activePoolId: poolId },
  }),
  setActiveIdoPool:
    (poolId: number) => async (dispatch: AppDispatch, store: getRootState) => {
      let pools = store().launchpadReducer.pools;
      let currentPool = pools.filter((el: any) => el.poolId === poolId)[0];
      currentPool = { ...currentPool };
      dispatch(launchpadActionCreator.setCurrentPool(currentPool, poolId));
    },
  setSelectedTokenWhitelist: (tokenAddress: string): LaunchpadAction => ({
    type: LaunchpadEnum.SET_SELECTED_IDO_TOKEN_WHITELIST,
    payload: tokenAddress,
  }),
  setTokenBuyDetails: (buyDetails: {
    selectedToken?: string;
    tokenPrice?: number;
    amountIn?: number;
    amountOut?: number;
    tokenInBalance?: number;
    tokenOutBalance?: number;
    decimal?: number;
  }): LaunchpadAction => ({
    type: LaunchpadEnum.SET_IDO_TOKEN_BUY_DETAILS,
    payload: buyDetails,
  }),
  getLaunchpadPools:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await initExonNode();
      const tronWebWallet = await (window as any).tronWeb;
      const currentAccount = store().AccountReducer.currentAccount;
      if (!tronWeb) return;

      let poolsAddressesListResult;
      let poolsAddressesList;
      let allPools = [];
      try {
        poolsAddressesListResult = await getTransactionBuilder(
          Config().LAUNCHPAD_FACTORY,
          'getAllIDOs()',
          [],
        );
        poolsAddressesList = poolsAddressesListResult[0];
      } catch (error) {
        console.log(error);
      }

      let poolsInfo;
      try {
        poolsInfo = await getLaunchPadPoolInfo();
        poolsInfo = poolsInfo.data;
      } catch (error) {
        console.log(error);
      }

      for (let i = 0; i < poolsAddressesList.length; i++) {
        console.log(poolsAddressesList);
        const currentPoolAddress = await tronWeb.address.fromHex(
          poolsAddressesList[i],
        );

        dispatch(launchpadActionCreator.setUserDataIsFetched(false, i + 1));
        let currentPool;
        let saleTokenInfo;
        let start;
        let end;
        let maxAllocation;
        let minAllocation;
        let refPercent;
        let buyTokens;
        let tokenPrices;
        let whiteListTokens;
        let whiteListAmounts;
        let addressVariables;
        let boolVariables;
        let total;
        let totalIDOAmount;
        let variables;
        let active;

        try {
          //currentPool = await launchpadContract.getLPStorage().call()
          currentPool = await getTransactionBuilder(
            currentPoolAddress,
            'getLPStorage()',
            [],
          );
          currentPool = currentPool[0];
          debugger;
          console.log(currentPool);
        } catch (error) {
          console.log(error);
        }
        if (!currentPool) return;

        try {
          active = await getTransactionBuilder(
            currentPoolAddress,
            'checkIDOActive()',
            [],
          );
          console.log(active);
          active = active[0];
        } catch (error) {
          console.log(error);
          active = false;
        }

        variables = currentPool.variables.map((el: any) => el.toNumber());

        maxAllocation = +tronWeb.fromSun(variables[0]);
        minAllocation = +tronWeb.fromSun(variables[1]);
        refPercent = variables[3];
        total = +tronWeb.fromSun(variables[2]);
        total = total - (total / (100 + refPercent)) * refPercent;
        start = variables[4];
        end = variables[5];
        totalIDOAmount = +tronWeb.fromSun(variables[6]);

        addressVariables = currentPool.addressVariables.map((el: string) =>
          tronWeb.address.fromHex(el),
        );

        tokenPrices = currentPool.tokenPrices.map((el: any) => el.toNumber());

        boolVariables = {
          withWithdraw: currentPool.boolVariables[0],
          sameUser: currentPool.boolVariables[1],
          byTime: currentPool.boolVariables[2],
        };

        buyTokens = currentPool.buyTokens.map((el: string) =>
          tronWeb.address.fromHex(el),
        );

        whiteListTokens = currentPool.whiteListTokens.map((el: string) =>
          tronWeb.address.fromHex(el),
        );

        whiteListAmounts = currentPool.whiteListAmounts.map((el: any) =>
          el.toNumber(),
        );
        whiteListAmounts = whiteListAmounts.map(
          (el: number) => +tronWeb.fromSun(el),
        );

        console.log(poolsInfo);
        let currentInfo: any;
        poolsInfo?.map((el: { pool_ido_addresses: any[] }) =>
          el.pool_ido_addresses?.map(address => {
            if (address.Address === currentPoolAddress)
              return (currentInfo = el);
            else return false;
          }),
        );

        console.log(currentInfo);

        let currentSocialMedia;
        let poolDescriptionInfo;
        if (currentInfo) {
          currentSocialMedia = currentInfo?.social_ido_types?.map(
            (el: { name: any; link: any; Poolname: any; sequences: any }) => {
              return {
                name: el.name.split('_')[0],
                link: el.link,
                PoolName: el.Poolname,
                sequences: el.sequences,
              };
            },
          );
          currentSocialMedia.sort(
            (a: { sequences: number }, b: { sequences: number }) =>
              a.sequences - b.sequences,
          );

          poolDescriptionInfo = {
            poolTitle: currentInfo.PoolTitle,
            poolName: currentInfo.PoolName,
            poolDescription: currentInfo.Description,
            websiteLink: currentInfo.websiteLink,
            image: 'https://content.exoncenter.com' + currentInfo.Image.url,
            socialMedia: currentSocialMedia,
          };
        }

        console.log('POOL DESCR INFO', poolDescriptionInfo);

        try {
          saleTokenInfo = await getTokensByAddress(addressVariables[2]);
          saleTokenInfo = saleTokenInfo.data.trc20_tokens
            .filter((el: { name: string }) => el.name !== 'EXON')
            .map(
              (el: {
                totalTurnOver: number;
                decimals: number;
                contract_address: any;
                symbol: any;
                name: any;
                icon_url: any;
              }) => {
                return {
                  address: el.contract_address,
                  symbol: el.symbol,
                  name: el.name,
                  decimal: el.decimals,
                  icon: el.icon_url,
                  totalSupply: el.totalTurnOver,
                };
              },
            );

          saleTokenInfo = saleTokenInfo[0];
        } catch (error) {
          console.log(error);
        }
        if (!saleTokenInfo) {
          saleTokenInfo = {
            address: addressVariables[2],
            name: 'AnacCode',
            symbol: 'ANAC',
            decimal: 6,
            icon: poolDescriptionInfo?.image,
            totalSupply: 0,
          };
        }
        console.log(saleTokenInfo);

        let whitelistedToken;
        let whitelistedTokenHistory;
        let boughtTokens = [];
        let saleTokenAmount;
        let referalAmount;

        for (let i = 0; i < currentPool.whiteListTokens.length; i++) {
          let whitelist;

          try {
            whitelist = await getTransactionBuilder(
              currentPoolAddress,
              'whiteListLock()',
              [currentAccount, whiteListTokens[i]],
            );
            //await lpContract.whiteListLock(currentAccount, currentPool.whiteListTokens[i]).call()
            whitelist = whitelist[0].toNumber();
            whitelist = +tronWeb.fromSun(whitelist);
          } catch (error) {
            console.log(error);
          }
          if (!!whitelist) {
            whitelistedToken = { token: whiteListTokens[i], amount: whitelist };
          }
        }
        if (!whitelistedToken) {
          whitelistedToken = { token: '', amount: 0 };
        }
        for (let j = 0; j < currentPool.whiteListTokens.length; j++) {
          let whitelistHistory;

          try {
            whitelistHistory = await getTransactionBuilder(
              currentPoolAddress,
              'whiteListLock()',
              [currentAccount, whiteListTokens[j]],
            );
            //await lpContract.whiteListLock(currentAccount, currentPool.whiteListTokens[i]).call()
            whitelistHistory = whitelistHistory[0].toNumber();
            whitelistHistory = +tronWeb.fromSun(whitelistHistory);
          } catch (error) {
            console.log(error);
          }
          if (!!whitelistHistory) {
            whitelistedTokenHistory = {
              token: whiteListTokens[j],
              amount: whitelistHistory,
            };
          }
        }
        if (!whitelistedToken) {
          whitelistedTokenHistory = { token: '', amount: 0 };
        }
        for (let i = 0; i < currentPool.buyTokens.length; i++) {
          let buyToken;

          try {
            buyToken = await getTransactionBuilder(
              currentPoolAddress,
              'tokenIdBuyTokenAmounts()',
              [currentAccount, buyTokens[i]],
            );
            //lpContract.tokenIdBuyTokenAmounts(currentAccount, currentPool.buyTokens[i]).call()
            buyToken = buyToken[0].toNumber();
            buyToken = +tronWeb.fromSun(buyToken);
          } catch (error) {
            console.log(error);
          }
          if (!!buyToken) {
            boughtTokens.push({ token: buyTokens[i], amount: buyToken });
          }
        }
        console.log(boughtTokens);

        try {
          saleTokenAmount = await getTransactionBuilder(
            currentPoolAddress,
            'tokenIdSaleAmount()',
            [currentAccount],
          );
          //lpContract.tokenIdSaleAmount(currentAccount).call()
          saleTokenAmount = saleTokenAmount[0].toNumber();
          saleTokenAmount = +tronWeb.fromSun(saleTokenAmount);
          saleTokenAmount = +saleTokenAmount.toFixed(2);
        } catch (error) {
          console.log(error);
          saleTokenAmount = 0;
        }

        try {
          referalAmount = await getTransactionBuilder(
            currentPoolAddress,
            'tokenIdRefAmount()',
            [currentAccount],
          );
          //lpContract.tokenIdRefAmount(currentAccount).call()
          referalAmount = referalAmount[0].toNumber();
          referalAmount = +tronWeb.fromSun(referalAmount);
        } catch (error) {
          console.log(error);
          referalAmount = 0;
        }

        const userInfo: LaunchpadUserInfo = {
          whitelistedToken,
          whitelistedTokenHistory,
          boughtTokens,
          saleTokenAmount,
          referalAmount,
        };
        console.log('USER INFO', userInfo);

        currentPool = {
          poolId: i + 1,
          poolAddress: currentPoolAddress,
          active,
          name: currentPool.name,
          saleTokenInfo,
          start,
          end,
          maxAllocation,
          minAllocation,
          refPercent,
          buyTokens,
          tokenPrices,
          whiteListTokens,
          whiteListAmounts,
          addressVariables,
          boolVariables,
          total,
          totalIDOAmount,
          poolDescriptionInfo,
          userInfo,
          userDataPoolFetched: false,
        };

        allPools.push(currentPool);
      }
      dispatch(launchpadActionCreator.setLaunchpadPools(allPools));
      if (currentAccount) {
        for (let i = 0; i < allPools.length; i++) {
          dispatch(launchpadActionCreator.setUserDataIsFetched(true, i + 1));
        }
      }
    },
  getActivePool:
    (poolId: number) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await initExonNode();
      // const tronWebWallet = await (window as any).tronWeb;
      const currentAccount = store().AccountReducer.currentAccount;
      const currentAddress = store().WalletReducer.address;
      if (!tronWeb) return;

      const activePoolAddress =
        store().launchpadReducer.pools[poolId - 1].poolAddress;
      const currentPoolAddress = await tronWeb.address.fromHex(
        activePoolAddress,
      );
      let currentPoolId;
      if (poolId === undefined) {
        currentPoolId = store().launchpadReducer.activeIdo.poolId;
      } else {
        currentPoolId = poolId;
      }

      dispatch(
        launchpadActionCreator.setUserDataIsFetched(false, currentPoolId),
      );

      let currentPool;
      let saleTokenInfo;
      let start;
      let end;
      let maxAllocation;
      let minAllocation;
      let refPercent;
      let buyTokens;
      let tokenPrices;
      let whiteListTokens;
      let whiteListAmounts;
      let addressVariables;
      let boolVariables;
      let total;
      let totalIDOAmount;
      let variables;
      let active;

      try {
        //currentPool = await launchpadContract.getLPStorage().call()
        currentPool = await getTransactionBuilder(
          currentPoolAddress,
          'getLPStorage()',
          [],
        );
        currentPool = currentPool[0];
        console.log(currentPool);
      } catch (error) {
        console.log(error);
      }
      if (!currentPool) return;

      try {
        active = await getTransactionBuilder(
          currentPoolAddress,
          'checkIDOActive()',
          [],
        );
        active = active[0];
      } catch (error) {
        console.log(error);
        active = false;
      }

      let poolsInfo;
      try {
        poolsInfo = await getLaunchPadPoolInfo();
        poolsInfo = poolsInfo.data;
      } catch (error) {
        console.log(error);
      }

      variables = currentPool.variables.map((el: any) => el.toNumber());
      maxAllocation = +tronWeb.fromSun(variables[0]);
      minAllocation = +tronWeb.fromSun(variables[1]);
      refPercent = variables[3];
      total = +tronWeb.fromSun(variables[2]);
      total = total - (total / (100 + refPercent)) * refPercent;
      start = variables[4];
      end = variables[5];
      totalIDOAmount = +tronWeb.fromSun(variables[6]);

      addressVariables = currentPool.addressVariables.map((el: string) =>
        tronWeb.address.fromHex(el),
      );

      tokenPrices = currentPool.tokenPrices.map((el: any) => el.toNumber());

      boolVariables = {
        withWithdraw: currentPool.boolVariables[0],
        sameUser: currentPool.boolVariables[1],
        byTime: currentPool.boolVariables[2],
      };

      buyTokens = currentPool.buyTokens.map((el: string) =>
        tronWeb.address.fromHex(el),
      );

      whiteListTokens = currentPool.whiteListTokens.map((el: string) =>
        tronWeb.address.fromHex(el),
      );

      whiteListAmounts = currentPool.whiteListAmounts.map((el: any) =>
        el.toNumber(),
      );
      whiteListAmounts = whiteListAmounts.map(
        (el: number) => +tronWeb.fromSun(el),
      );

      let currentInfo: any;
      poolsInfo?.map((el: { pool_ido_addresses: any[] }) =>
        el.pool_ido_addresses?.map(address => {
          if (address.Address === currentPoolAddress) return (currentInfo = el);
          else return false;
        }),
      );

      let currentSocialMedia;
      let poolDescriptionInfo;
      if (currentInfo) {
        currentSocialMedia = currentInfo?.social_ido_types?.map(
          (el: { name: any; link: any; Poolname: any; sequences: any }) => {
            return {
              name: el.name.split('_')[0],
              link: el.link,
              PoolName: el.Poolname,
              sequences: el.sequences,
            };
          },
        );
        currentSocialMedia.sort(
          (a: { sequences: number }, b: { sequences: number }) =>
            a.sequences - b.sequences,
        );

        poolDescriptionInfo = {
          poolTitle: currentInfo.PoolTitle,
          poolName: currentInfo.PoolName,
          poolDescription: currentInfo.Description,
          websiteLink: currentInfo.websiteLink,
          image: 'https://content.exoncenter.com' + currentInfo.Image.url,
          socialMedia: currentSocialMedia,
        };
      }

      console.log('POOL DESCR INFO', poolDescriptionInfo);

      try {
        saleTokenInfo = await getTokensByAddress(addressVariables[2]);
        saleTokenInfo = saleTokenInfo.data.trc20_tokens
          .filter((el: { name: string }) => el.name !== 'EXON')
          .map(
            (el: {
              totalTurnOver: number;
              decimals: number;
              contract_address: any;
              symbol: any;
              name: any;
              icon_url: any;
            }) => {
              return {
                address: el.contract_address,
                symbol: el.symbol,
                name: el.name,
                decimal: el.decimals,
                icon: el.icon_url,
                totalSupply: el.totalTurnOver,
              };
            },
          );

        saleTokenInfo = saleTokenInfo[0];
      } catch (error) {
        console.log(error);
      }
      if (!saleTokenInfo) {
        saleTokenInfo = {
          address: addressVariables[2],
          name: 'AnacCode',
          symbol: 'ANAC',
          decimal: 6,
          icon: poolDescriptionInfo?.image,
          totalSupply: 0,
        };
      }
      console.log(saleTokenInfo);

      let whitelistedToken;
      let whitelistedTokenHistory;
      let boughtTokens = [];
      let saleTokenAmount;
      let referalAmount;

      for (let i = 0; i < currentPool.whiteListTokens.length; i++) {
        let whitelist;

        try {
          whitelist = await getTransactionBuilder(
            currentPoolAddress,
            'whiteListLock()',
            [currentAccount, whiteListTokens[i]],
          );
          //await lpContract.whiteListLock(currentAccount, currentPool.whiteListTokens[i]).call()
          whitelist = whitelist[0].toNumber();
          whitelist = +tronWeb.fromSun(whitelist);
        } catch (error) {
          console.log(error);
        }
        if (!!whitelist) {
          whitelistedToken = { token: whiteListTokens[i], amount: whitelist };
        }
      }
      if (!whitelistedToken) {
        whitelistedToken = { token: '', amount: 0 };
      }
      for (let j = 0; j < currentPool.whiteListTokens.length; j++) {
        let whitelistHistory;

        try {
          whitelistHistory = await getTransactionBuilder(
            currentPoolAddress,
            'whiteListLockHistory()',
            [currentAccount, whiteListTokens[j]],
          );
          //await lpContract.whiteListLock(currentAccount, currentPool.whiteListTokens[i]).call()
          whitelistHistory = whitelistHistory[0].toNumber();
          whitelistHistory = +tronWeb.fromSun(whitelistHistory);
        } catch (error) {
          console.log(error);
        }
        if (!!whitelistHistory) {
          whitelistedTokenHistory = {
            token: whiteListTokens[j],
            amount: whitelistHistory,
          };
        }
      }
      if (!whitelistedTokenHistory) {
        whitelistedTokenHistory = { token: '', amount: 0 };
      }
      for (let i = 0; i < currentPool.buyTokens.length; i++) {
        let buyToken;

        try {
          buyToken = await getTransactionBuilder(
            currentPoolAddress,
            'tokenIdBuyTokenAmounts()',
            [currentAccount, buyTokens[i]],
          );
          //lpContract.tokenIdBuyTokenAmounts(currentAccount, currentPool.buyTokens[i]).call()
          buyToken = buyToken[0].toNumber();
          buyToken = +tronWeb.fromSun(buyToken);
        } catch (error) {
          console.log(error);
        }
        if (!!buyToken) {
          boughtTokens.push({ token: buyTokens[i], amount: buyToken });
        }
      }
      console.log(boughtTokens);

      try {
        saleTokenAmount = await getTransactionBuilder(
          currentPoolAddress,
          'tokenIdSaleAmount()',
          [currentAccount],
        );
        //lpContract.tokenIdSaleAmount(currentAccount).call()
        saleTokenAmount = saleTokenAmount[0].toNumber();
        saleTokenAmount = +tronWeb.fromSun(saleTokenAmount);
        saleTokenAmount = +saleTokenAmount.toFixed(2);
      } catch (error) {
        console.log(error);
        saleTokenAmount = 0;
      }

      try {
        referalAmount = await getTransactionBuilder(
          currentPoolAddress,
          'tokenIdRefAmount()',
          [currentAccount],
        );
        //lpContract.tokenIdRefAmount(currentAccount).call()
        referalAmount = referalAmount[0].toNumber();
        referalAmount = +tronWeb.fromSun(referalAmount);
      } catch (error) {
        console.log(error);
        referalAmount = 0;
      }

      const userInfo: LaunchpadUserInfo = {
        whitelistedToken,
        whitelistedTokenHistory,
        boughtTokens,
        saleTokenAmount,
        referalAmount,
      };
      console.log('USER INFO', userInfo);

      currentPool = {
        poolId: currentPoolId,
        poolAddress: currentPoolAddress,
        active,
        name: currentPool.name,
        saleTokenInfo,
        start,
        end,
        maxAllocation,
        minAllocation,
        refPercent,
        buyTokens,
        tokenPrices,
        whiteListTokens,
        whiteListAmounts,
        addressVariables,
        boolVariables,
        total,
        totalIDOAmount,
        poolDescriptionInfo,
        userInfo,
        userDataIsFetched: currentAccount ? true : false,
      };
      if (store().launchpadReducer.buyDetails.selectedToken) {
        const tokenInContract = await tronWeb.contract(
          ITRX20.abi,
          store().launchpadReducer.buyDetails.selectedToken,
        );

        let tokenInBalance;
        let tokenInDecimal;

        try {
          tokenInBalance = await tokenInContract
            .balanceOf(currentAddress)
            .call();
          tokenInBalance = tokenInBalance.toNumber();
          tokenInBalance = +tronWeb.fromSun(tokenInBalance);
          tokenInBalance = +tokenInBalance.toFixed(6);
          tokenInDecimal = await tokenInContract.decimals().call();
        } catch (error) {
          console.log(error);
        }
        if (tokenInBalance) {
          let tokenOutBalance = total - totalIDOAmount;
          tokenOutBalance = +tokenOutBalance.toFixed(6);

          dispatch(
            launchpadActionCreator.setTokenBuyDetails({
              decimal: tokenInDecimal,
              tokenInBalance,
              tokenOutBalance,
            }),
          );
        }
      }

      let pools = store().launchpadReducer.pools;
      dispatch(
        launchpadActionCreator.setCurrentPool(currentPool, currentPoolId),
      );
      pools[currentPoolId - 1] = currentPool;
      dispatch(launchpadActionCreator.setLaunchpadPools(pools));
      dispatch(launchpadActionCreator.setLaunchpadState('initial'));
    },
  joinWhitelistIdo:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const currentAccount = store().AccountReducer.currentAccount;
      const whitelistDetails = store().launchpadReducer.whitelistDetails;
      const activeIdo = store().launchpadReducer.activeIdo;
      const currentAddress = store().WalletReducer.address;

      if (!tronWeb || !currentAccount) return;
      dispatch(launchpadActionCreator.setLaunchpadState('joinToIDO'));
      dispatch(ApplicationAcionCreator.setTransactionSteps());

      const LPContract = await tronWeb.contract(IDO.abi, activeIdo.poolAddress);
      const tokenInContract = await tronWeb.contract(
        ITRX20.abi,
        whitelistDetails.selectedToken,
      );
      let amount = whitelistDetails.tokenAmount;
      amount = tronWeb.toSun(amount);

      let approveTrx;
      let balanceTokenIn = await tokenInContract
        .balanceOf(currentAddress)
        .call();
      if (balanceTokenIn.toBigInt() < BigInt(parseInt(amount))) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            '',
            `Balance -- insufficient amount`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      dispatch(launchpadActionCreator.setLaunchpadState('joinToIDO'));
      dispatch(
        ApplicationAcionCreator.setTransactionSteps([`Approve join token`]),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve join token`,
          true,
          false,
        ),
      );

      try {
        approveTrx = await tokenInContract
          .approve(activeIdo.poolAddress, amount)
          .send();
      } catch (error: any) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            approveTrx,
            `${error.message}`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      if (await getTransactionResult(approveTrx, tronWeb)) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            approveTrx,
            `Unexpected error`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve join token`,
          false,
          true,
        ),
      );
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Approve',
          'Success',
          approveTrx,
          `Approve join token`,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          true,
          false,
        ),
      );
      let joinToPoolTrx;
      try {
        console.log(
          'WHITELIST ADD',
          currentAccount,
          whitelistDetails.selectedToken,
        );

        joinToPoolTrx = await LPContract.addToWhiteList(
          currentAccount,
          whitelistDetails.selectedToken,
        ).send({ feeLimit: 100000000 });
      } catch (error: any) {
        console.log(error);
        dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            joinToPoolTrx,
            `${error.message}`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          false,
          true,
        ),
      );
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          true,
          false,
        ),
      );
      if (await getTransactionResult(joinToPoolTrx, tronWeb)) {
        dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            joinToPoolTrx,
            `An error occured during join to pool`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'IDO',
          'Success',
          joinToPoolTrx,
          `Success join to pool`,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          false,
          true,
        ),
      );
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          false,
          true,
        ),
      );
    },
  buyIdoTokens: () => async (dispatch: AppDispatch, store: getRootState) => {
    const tronWeb = await store().WalletReducer.tronWeb;
    const currentAccount = store().AccountReducer.currentAccount;
    const buyDetails = store().launchpadReducer.buyDetails;
    const activeIdo = store().launchpadReducer.activeIdo;
    const currentAddress = store().WalletReducer.address;

    if (!tronWeb || !currentAccount) return;
    dispatch(launchpadActionCreator.setLaunchpadState('buyTokens'));
    dispatch(ApplicationAcionCreator.setTransactionSteps());

    const LPContract = await tronWeb.contract(IDO.abi, activeIdo.poolAddress);
    const tokenInContract = await tronWeb.contract(
      ITRX20.abi,
      buyDetails.selectedToken,
    );
    let amount = buyDetails.amountIn;
    amount = tronWeb.toSun(amount);

    let approveTrx;
    let balanceTokenIn = await tokenInContract.balanceOf(currentAddress).call();
    if (balanceTokenIn.toBigInt() < BigInt(amount)) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'IDO',
          'Error',
          '',
          `Balance -- insufficient amount`,
        ),
      );
      dispatch(launchpadActionCreator.setLaunchpadState('initial'));
      return;
    }
    dispatch(launchpadActionCreator.setLaunchpadState('buyTokens'));
    dispatch(
      ApplicationAcionCreator.setTransactionSteps([`Approve buy token`]),
    );

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Approve buy token`,
        true,
        false,
      ),
    );

    try {
      approveTrx = await tokenInContract
        .approve(activeIdo.poolAddress, amount)
        .send();
    } catch (error: any) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'IDO',
          'Error',
          approveTrx,
          `${error.message}`,
        ),
      );
      dispatch(launchpadActionCreator.setLaunchpadState('initial'));
      return;
    }
    if (await getTransactionResult(approveTrx, tronWeb)) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'IDO',
          'Error',
          approveTrx,
          `Unexpected error`,
        ),
      );
      dispatch(launchpadActionCreator.setLaunchpadState('initial'));
      return;
    }
    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Approve buy token`,
        false,
        true,
      ),
    );
    dispatch(
      ApplicationAcionCreator.setNotificationStatus(
        true,
        true,
        'Approve',
        'Success',
        approveTrx,
        `Approve buy token`,
      ),
    );

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        true,
        false,
      ),
    );
    let joinToPoolTrx;
    try {
      joinToPoolTrx = await LPContract.joinToIDO(
        buyDetails.selectedToken,
        amount,
        currentAccount,
      ).send({ feeLimit: 100000000 });
    } catch (error: any) {
      console.log(error);
      dispatch(ApplicationAcionCreator.setTransactionStatus(false));
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'IDO',
          'Error',
          joinToPoolTrx,
          `${error.message}`,
        ),
      );
      dispatch(launchpadActionCreator.setLaunchpadState('initial'));
      return;
    }
    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        false,
        true,
      ),
    );
    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(`Finished`, true, false),
    );
    if (await getTransactionResult(joinToPoolTrx, tronWeb)) {
      dispatch(ApplicationAcionCreator.setTransactionStatus(false));
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'IDO',
          'Error',
          joinToPoolTrx,
          `An error occured during buy tokens`,
        ),
      );
      dispatch(launchpadActionCreator.setLaunchpadState('initial'));
      return;
    }
    dispatch(
      ApplicationAcionCreator.setNotificationStatus(
        true,
        true,
        'IDO',
        'Success',
        joinToPoolTrx,
        `Success bought tokens`,
      ),
    );
    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(`Finished`, false, true),
    );
  },
  claimWhitelistTokens:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const activeIdo = store().launchpadReducer.activeIdo;
      const tokenId = store().AccountReducer.currentAccount;
      dispatch(
        launchpadActionCreator.setLaunchpadState('claimWhitelistIDOTokens'),
      );
      dispatch(ApplicationAcionCreator.setTransactionSteps());
      if (!activeIdo || !tokenId) return;
      let LPContract;
      LPContract = await tronWeb.contract(IDO.abi, activeIdo.poolAddress);
      let claimWithdrawTrx;
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          true,
          false,
        ),
      );
      try {
        claimWithdrawTrx = await LPContract.claimToken(tokenId).send({
          feeLimit: 100000000,
        });
      } catch (error: any) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            claimWithdrawTrx,
            `${error.message}`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          false,
          true,
        ),
      );
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          true,
          false,
        ),
      );
      if (await getTransactionResult(claimWithdrawTrx, tronWeb)) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'IDO',
            'Error',
            claimWithdrawTrx,
            `Unexpected error`,
          ),
        );
        dispatch(launchpadActionCreator.setLaunchpadState('initial'));
        return;
      }
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Approve',
          'Success',
          claimWithdrawTrx,
          `Withdraw whitelist and project tokens`,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          false,
          true,
        ),
      );
    },
  getBuyTokenBalances:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const buyDetails = store().launchpadReducer.buyDetails;
      const currentAccount = store().WalletReducer.address;
      const activeIdo = store().launchpadReducer.activeIdo;
      const tronWeb = await (window as any).tronWeb;

      const tokenInContract = await tronWeb.contract(
        ITRX20.abi,
        buyDetails.selectedToken,
      );

      let tokenInBalance;
      let tokenInDecimal;

      try {
        tokenInBalance = await tokenInContract.balanceOf(currentAccount).call();
        tokenInBalance = tokenInBalance.toNumber();
        tokenInBalance = +tronWeb.fromSun(tokenInBalance);
        tokenInBalance = +tokenInBalance.toFixed(6);
        tokenInDecimal = await tokenInContract.decimals().call();
      } catch (error) {
        console.log(error);
      }

      if (activeIdo && buyDetails.selectedToken) {
        let tokenOutBalance = activeIdo.total - activeIdo.totalIDOAmount;
        tokenOutBalance = +tokenOutBalance.toFixed(6);

        dispatch(
          launchpadActionCreator.setTokenBuyDetails({
            decimal: tokenInDecimal,
            tokenInBalance,
            tokenOutBalance,
          }),
        );
      }
    },
};
